const LOCAL = 'ru'
const TIMEZONE = 'Europe/Moscow'
export const formatDateTime = dateTime => {
  if (!dateTime) return
  return new Intl.DateTimeFormat(LOCAL, {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  }).format(dateTime) +
    ' в ' +
    new Intl.DateTimeFormat(LOCAL, {
      hour: '2-digit',
      minute: '2-digit'
    }).format(dateTime)
}

export const formatDateShort = dateTime => {
  if (!dateTime) return
  return new Intl.DateTimeFormat(LOCAL, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: TIMEZONE
  }).format(dateTime)
}
export const formatDate = dateTime => {
  if (!dateTime) return
  return new Intl.DateTimeFormat(LOCAL, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    timeZone: TIMEZONE
  }).format(dateTime)
}
export const formatDateT = dateTime => {
  if (!dateTime) return
  return new Intl.DateTimeFormat(LOCAL, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  }).format(dateTime)
}

export const formatMonth = dateTime => {
  return new Intl.DateTimeFormat(LOCAL, {
    month: 'long'
  }).format(dateTime)
}

export const formatDayOfWeek = dateTime => {
  if (!dateTime) return
  return new Intl.DateTimeFormat(LOCAL, {
    weekday: 'short'
  }).format(dateTime)
}

export const formatMonthYear = dateTime => {
  if (!dateTime) return
  return new Intl.DateTimeFormat(LOCAL, {
    month: 'long',
    year: 'numeric'
  }).format(dateTime)
}

export const formatPrice = price => {
  if (typeof price !== 'number') {
    price = Number(price)
  }
  return new Intl.NumberFormat(LOCAL, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(price)
}

export const formatStringAsNumber = str => {
  const res = str.replace(/[^0-9.,]/g, '')
  return Number(res.replace(/,/g, '.'))
}
