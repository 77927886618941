<template>
  <v-alert
    dense
    outlined
    type="error"
    max-width="540"
    style="margin: 5px auto"
  >
    <slot></slot>
  </v-alert>
</template>

<script>
export default {
  name: 'ErrorMessage'
}
</script>

<style scoped>

</style>
