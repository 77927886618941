import axios from 'axios'

const URL = process.env.VUE_APP_API_PROXI_URI

const API_SRO_LIST = 'sro/list'
const API_DICTIONARIES_GET = 'dictionaries/get'
const API_SRO_ITEM = 'sro/{id}'
const API_MEMBER_LIST_BY_SRO = 'sro/{id}/member/list'
const API_MEMBER_LIST = 'sro/all/member/list'
const API_MEMBER_ITEM = 'member/{id}/info'
const API_MEMBER_ITEM_FRAME = 'member/{id}/frame'
const API_STATEMENT_ITEM = 'statement/{token}'

const API_GENERATE_REGISTER_MEMBERS = 'sro/{id}/register/generate'
const API_DOWNLOAD_REGISTER_MEMBERS = 'sro/{id}/register/download'
const API_DOWNLOAD_CART_MEMBER = 'member/{id}/cart/download'
const API_DOWNLOAD_STATEMENT_MEMBER = 'member/{id}/statement/download'
const API_DOWNLOAD_STATEMENT_QRCODE = 'statement/{token}/download/{file}'

const getResponse = async function (uri, options = {}) {
  return axios({
    method: 'post',
    url: URL + uri,
    data: options,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

const getData = async function (uri, options = {}) {
  return await getResponse(uri, options)
    .then(({ data }) => {
      return data
    }).catch(e => {
      return {
        success: false,
        message: e.message
      }
    })
}

const download = async function (uri) {
  return await axios.get(
    URL + uri,
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    }
  ).then(response => {
    if (response.headers['content-type'] === 'application/json') {
      return JSON.parse(new TextDecoder().decode(response.data))
    }

    return {
      success: true,
      data: response.data,
      contentType: response.headers['content-type']
    }
  }).catch(({ message }) => ({
    success: false,
    message
  }))
}
export async function getSroList (options = {}) {
  return await getData(API_SRO_LIST, options)
}

export async function getDictionaries (options) {
  return getData(API_DICTIONARIES_GET, options)
}

export async function getSroItem (id) {
  return getData(API_SRO_ITEM.replace('{id}', id))
}

export async function getStatementItem (token) {
  return getData(API_STATEMENT_ITEM.replace('{token}', token))
}

export const getMemberListBySro = async (sroId, options) => {
  return getData(API_MEMBER_LIST_BY_SRO.replace('{id}', sroId), options)
}

export const getMemberList = async (options) => {
  return getData(API_MEMBER_LIST, options)
}

export const getMember = async (memberId) => {
  return getData(API_MEMBER_ITEM.replace('{id}', memberId))
}

export const getMemberFrame = async (memberId) => {
  return getData(API_MEMBER_ITEM_FRAME.replace('{id}', memberId))
}

export const generateRegisterMembers = async (sroId) => {
  return getData(API_GENERATE_REGISTER_MEMBERS.replace('{id}', sroId))
}

export const downloadRegisterMembers = async (sroId) => {
  return download(API_DOWNLOAD_REGISTER_MEMBERS.replace('{id}', sroId))
}

export const downloadCartMember = async (id) => {
  return download(API_DOWNLOAD_CART_MEMBER.replace('{id}', id))
}

export const downloadStatementMember = async (id) => {
  return download(API_DOWNLOAD_STATEMENT_MEMBER.replace('{id}', id))
}

export const downloadStatementQrCode = async (token, file) => {
  let url = API_DOWNLOAD_STATEMENT_QRCODE
  url = url.replace('{token}', token)
  url = url.replace('{file}', file)
  return download(url)
}
