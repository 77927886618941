<template>
  <div class="spinner">
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'Spinner'
}
</script>

<style scoped>

</style>
